// import { decode } from './polyline'
import L from 'leaflet'
export const H22YOU_URL = process.env.REACT_APP_COSTING
export const ROUTING_URL = process.env.REACT_APP_ROUTING
import { geojsonToWKT } from "@terraformer/wkt"

const conversion_factor_nh3 = 0.1765
// INTERFACES to be defined/Frozen!!!
export const buildCostRequest = ({
  settings, results, profile
}) => {

  switch (profile) {

    case 'hyviate':
      return {
        json: {"aircraft":{
          "payload": settings.payload * 1000,
          "capex": settings.capex_transport_asset * 1e6,
          "opex": settings.opex_transport_asset / 100,
          "wacc": settings.wacc / 100,
          "speed": settings.speed,
          "distance": results[ROUTING_URL].data.distance.toFixed(0),
          "reliability": settings.reliability / 100,
          "fuel_cost": settings.fuel_cost,
          "fuel_burn": settings.fuel_burn,
          "turn_around_time": settings.turn_around_time,
          "lifetime": settings.lifetime_transport_asset,
          "burns_payload": settings.burns_payload,
        },"liquifier":{
        "electricity_cost":settings.elec_cost_at_export,
        "specific_capex":settings.liquefaction_capex,
        "segment_elec_energy":settings.liquefaction_energy}
        }
      }
    case 'pipeline':
      return {
        json: {
          "throughput": settings.throughput * 1e6,
          "specific_capex": settings.pipeline_specific_capex,
          "specific_opex": settings.pipeline_specific_opex / 100,
          "operating_pressure_electrolyser": settings.pipeline_input_pressure,
          "operating_pressure_high": settings.pipeline_high_pressure,
          "operating_pressure_low": settings.pipeline_low_pressure,
          "energy_cost": settings.compressor_energy_cost,
          "utilisation": settings.utilisation / 100,
          "length": results[ROUTING_URL].data.distance.toFixed(0) * 1000,
          "operating_temperature": settings.pipeline_operating_temperature,
          "compressor_efficiency": settings.compressor_energy_efficiency / 100,
          "pipe_segment_length": settings.pipeline_stage_length * 1000,
          "roughness": settings.pipeline_rougness * 1e-6,
          "lifetime": settings.lifetime,
          "wacc": settings.wacc / 100,
          "diameter": settings.pipeline_diameter / 100,
          "compressor_capex": settings.compressor_specific_capex,
          "turbine_compressor": settings.h2_as_fuel,
        }
      }
    case 'ammonia_ship':
      return {
        json: {
          "discount_rate": settings.wacc / 100,
          "throughput": settings.throughput * 1e6,
          "profile": profile,
          "distance": results[ROUTING_URL].data.distance.toFixed(0),
          "h2_cost_at_export": settings.h2_cost_at_export,
          "elec_cost_at_export": settings.elec_cost_at_export,
          "heat_cost_at_import": settings.heat_cost_at_import,
          "elec_cost_at_import": settings.elec_cost_at_import,
          "transport": {
            "capacity": settings.ammonia_payload, // in tNH3 converted to kgH2 on backend
            "capex": settings.capex_transport_asset * 1e6,
            "opex": settings.opex_transport_asset / 100,
            "speed": settings.speed,
            "fuel_cost": settings.fuel_cost,
            "fuel_use": settings.fuel_burn,  // TODO: harmonize variables words
            "charging_time": settings.turn_around_time,  // TODO: harmonize variables words
            "lifetime": settings.lifetime_transport_asset,
            "h2_consumed": settings.burns_payload, // TODO: harmonize variables words
          },
          "tank": {
            "capacity_tank": 56700,
            "capex_tank": 68000000,
            "elec_energy": 0.005,
            "opex_tank": 0.04
          },
          "conversion": {
            "specific_capex": settings.conversion_capex_nh3*1000 * settings.throughput*1e3 / 365 / conversion_factor_nh3,
            "specific_opex": 0.04,
            "segment_elec_energy": settings.conversion_energy_nh3 / conversion_factor_nh3 / 1000 *  (1/3.6*1000),
            "h2_consumed": false,
            "electricity_cost": settings.elec_cost_at_export,
          },
          "reconversion": {
            "heat_cost": settings.heat_cost_at_import,
            "electricity_cost": settings.elec_cost_at_import,
            "capacity": settings.throughput*1e6,
            "specific_capex": settings.reconversion_capex_nh3*1000 * settings.throughput*1e3 / 365 / conversion_factor_nh3,
            "specific_opex": 0.04,
            "heat_required": settings.reconversion_heat_nh3,
            "electricity_required":settings.reconversion_electricity_nh3,
            "h2_consumed": settings.nh3_for_cracking
          }
        }
      }
    case 'lohc_ship':
      return {
        json: {
          "discount_rate": settings.wacc / 100,
          "throughput": settings.throughput * 1e6,
          "profile": profile,
          "distance": results[ROUTING_URL].data.distance.toFixed(0),
          "h2_cost_at_export": 2,
          "elec_cost_at_export": settings.elec_cost_at_export,
          "heat_cost_at_import": settings.heat_cost_at_import,
          "elec_cost_at_import": settings.elec_cost_at_import,
          "transport": {
            "capacity": settings.payload_lohc, // in tNH3 converted to kgH2 on backend
            "capex": settings.capex_transport_asset * 1e6,
            "opex": settings.opex_transport_asset / 100,
            "speed": settings.speed,
            "fuel_cost": settings.fuel_cost,
            "fuel_use": settings.fuel_burn,  // TODO: harmonize variables words
            "charging_time": settings.turn_around_time,  // TODO: harmonize variables words
            "lifetime": settings.lifetime_transport_asset,
            "h2_consumed": settings.burns_payload, // TODO: harmonize variables words
          },
          "tank": {
            "capacity_tank": 51750,
            "capex_tank": 42000000,
            "elec_energy": 0.005,
            "opex_tank": 0.04
          },
          "conversion": {
            "specific_capex": 600e6,
            "specific_opex": 0.04,
            "segment_elec_energy": 9,
            "h2_consumed": false,
            "electricity_cost": settings.elec_cost_at_export,
          },
          "reconversion": {
            "heat_cost": settings.heat_cost_at_import,
            "electricity_cost": settings.elec_cost_at_import,
            "capacity": 4200e6,
            "specific_capex": 670e6,
            "specific_opex": 0.04,
            "heat_required": 13.6,
            "plant_power": 0.4,
            "h2_purification": 1.1,
            "h2_recovery_rate": 0.9,
            "psa_h2_recovery_rate": 0.98,
            "h2_consumed": settings.h2_consumed
          }
        }
      }
    case 'lh2_ship':
      return {
        json: {
          "discount_rate": settings.wacc / 100,
          "throughput": settings.throughput * 1e6,
          "profile": profile,
          "distance": results[ROUTING_URL].data.distance.toFixed(0),
          "h2_cost_at_export": settings.h2_cost_at_export,
          "elec_cost_at_export": settings.elec_cost_at_export,
          "heat_cost_at_import": settings.heat_cost_at_import,
          "elec_cost_at_import": settings.elec_cost_at_import,
          "transport": {
            "capacity": settings.payload * 1000, // in tNH3 converted to kgH2 on backend
            "capex": settings.capex_transport_asset * 1e6,
            "opex": settings.opex_transport_asset / 100,
            "speed": settings.speed,
            "fuel_cost": settings.fuel_cost,
            "fuel_use": settings.fuel_burn,  // TODO: harmonize variables words
            "charging_time": settings.turn_around_time,  // TODO: harmonize variables words
            "lifetime": settings.lifetime_transport_asset,
            "h2_consumed": settings.burns_payload, // TODO: harmonize variables words
          },
          "tank": {
            "capacity_tank": 3550000,
            "capex_tank": 320e6,
            "elec_energy": 0.005,
            "opex_tank": 0.04
          },
          "liquifier":{
            "electricity_cost":settings.elec_cost_at_export,
            "specific_capex":settings.liquefaction_capex,
            "segment_elec_energy":settings.liquefaction_energy}
        }
      }
  }
}

export const buildRouteRequest = ({
  profile,
  activeWaypoints,
  coords,
  settings
}) => {
  let accelerate_set = true
  let mask_set = null
  if (coords) {
    const coordsT = transposePolygone(coords)
    const polygon = L.polygon(coordsT)
    mask_set = geojsonToWKT(polygon.toGeoJSON().geometry)
    accelerate_set = false
  }
  return {

    json: {
      // API Currently requires WKT notation
      points_from: "Point(" + activeWaypoints[0].sourcelnglat[0] +
        " " + activeWaypoints[0].sourcelnglat[1] + ")",
      points_to: "Point(" + activeWaypoints.at(-1).sourcelnglat[0] +
        " " + activeWaypoints.at(-1).sourcelnglat[1] + ")",
      seafactor: (settings.seafactor ? settings.seafactor : 1),
      profile: profile,
      accelerate: accelerate_set,
      mask: mask_set
    }
  }
}

//leaflet eats LatLon as opposed to LonLat
export const parseTransportGeometry = (data) => {
  if (data.route.type === 'LineString') {
    const coordinates = []
    for (const feat of data.route.coordinates) {
      const array = [feat[1], feat[0]]
      coordinates.push(array)
    }
    return coordinates
  }
}

export const transposePolygone = (coords) => {
  const coordinates = []
  for (const feat of coords) {
    const array = [feat[1], feat[0]]
    coordinates.push(array)
  }
  return coordinates
}


// const coordsToGeoJson = (coords) => {
//   return {
//     type: "Polygon",
//     coordinates: parseTransportGeometry(coords)
//   }
// }


  // return {
  //   // json: {
  //   //   costing: valhalla_profile,
  //   //   costing_options: {
  //   //     [valhalla_profile]: { ...settings },
  //   //   },
  //   //   exclude_polygons: settings.exclude_polygons,
  //   //   locations: makeLocations(activeWaypoints),
  //   //   directions_options: {
  //   //     units: 'kilometers',
  //   //   },
  //   //   id: 'valhalla_directions',
  //   // },
  //   result: 10
  // }
// }

// const makeLocations = (waypoints) => {
//   const locations = []
//   for (const [idx, waypoint] of waypoints.entries()) {
//     const type = [0, waypoints.length - 1].includes(idx) ? 'break' : 'via'
//     locations.push({
//       lon: waypoint.displaylnglat[0],
//       lat: waypoint.displaylnglat[1],
//       type: type,
//     })
//   }

//   return locations
// }